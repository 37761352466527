<template>
  <b-row>
    <b-col cols="12" lg="6">
      <SensorLocationFilter
        v-model="sensorLocationsSync"
        :sensor-filter="sensorsSync"
        :default-sensor-location-ids="defaultSensorLocationIds"
      />
    </b-col>
    <b-col cols="12" lg="6">
      <SensorFilter
        v-model="sensorsSync"
        :location-filter="sensorLocationsSync"
        :default-sensor-ids="defaultSensorIds"
      />
    </b-col>
  </b-row>
</template>

<script>
import SensorFilter from '@/components/SensorFilter'
import SensorLocationFilter from '@/components/SensorLocationFilter'

export default {
  name: 'CombinedSensorAndSensorLocationFilter',
  components: { SensorFilter, SensorLocationFilter },
  props: {
    sensors: {
      required: true,
      type: Array
    },
    defaultSensorIds: {
      required: false,
      type: Array
    },
    locations: {
      required: true,
      type: Array
    },
    defaultSensorLocationIds: {
      required: false,
      type: Array
    }
  },
  computed: {
    sensorsSync: {
      get() {
        return this.sensors
      },
      set(val) {
        this.$emit('update:sensors', val)
      }
    },
    sensorLocationsSync: {
      get() {
        return this.locations
      },
      set(val) {
        this.$emit('update:locations', val)
      }
    }
  }
}
</script>
