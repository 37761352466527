<template>
  <GoldflamMultiselect
    v-model="selected"
    :create-tag-function="createTag"
    :empty-label="$t('components.sensorFilter.list-empty-label')"
    :placeholder="
      isLocationFilterSet
        ? $t('components.sensorFilter.all-sensors-for-location')
        : $t('components.sensorFilter.all-sensors')
    "
    :selectable-items="sensorOptions"
    :taggable="allowTagCreation"
  >
    <template v-slot:tag="props">
      <DatasetTagBadge
        v-if="props.option.value"
        :tag="{
          id: props.option.value.id,
          name: '# ' + props.option.value.id + ' - ' + props.option.value.sensor_location.city
        }"
        :allow-removal="allowAutoTagRemoval || !props.option.value.auto_tag"
        :remove-function="removeTag"
      />
    </template>
  </GoldflamMultiselect>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import { uniqBy as _uniqBy } from 'lodash'
import GoldflamMultiselect from '@/components/GoldflamMultiselect'
import DatasetTagBadge from '@/components/DatasetTagBadge'

export default {
  name: 'SensorFilter',
  components: { GoldflamMultiselect, DatasetTagBadge },
  mixins: [ApiMixin, NotificationMixin],
  props: {
    value: {
      required: true,
      type: Array
    },
    locationFilter: {
      required: false,
      type: Array
    },
    defaultSensorIds: {
      required: false,
      type: Array
    },
    allowTagCreation: {
      required: false,
      type: Boolean,
      default: false
    },
    allowAutoTagRemoval: {
      required: false,
      type: Boolean,
      default: true
    },
    multipleSensors: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      allOptions: []
    }
  },
  watch: {
    locationFilter: {
      handler() {
        if (this.isLocationFilterSet) {
          let newSelected = []
          this.selected.forEach(sensor => {
            this.locationFilter.forEach(filter => {
              if (filter.value.id === sensor.value.sensor_location.id) {
                newSelected.push(sensor)
              }
            })
          })
          this.selected = newSelected
        }
      },
      deep: true
    },
    defaultSensorIds: {
      handler: function () {
        let newDefaults = []
        this.defaultSensorIds.forEach(defaultId => {
          this.allOptions.forEach(sensor => {
            if (sensor.value.id === parseInt(defaultId)) {
              newDefaults.push(sensor)
            }
          })
        })
        this.selected = newDefaults
      }
    }
  },
  computed: {
    selected: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    isLocationFilterSet() {
      return this.locationFilter !== null && this.locationFilter.length > 0
    },
    sensorOptions() {
      if (this.isLocationFilterSet) {
        let options = []
        this.allOptions.forEach(option => {
          this.locationFilter.forEach(filter => {
            if (option.value.sensor_location.id === filter.value.id) {
              options.push(option)
            }
          })
        })
        return _uniqBy(options, e => {
          return e.value.id
        })
      } else {
        return this.allOptions
      }
    },
    locationFilterName() {
      return `# ${this.locationFilter.id} - ${this.locationFilter.city} - ${this.locationFilter.name}`
    }
  },
  mounted() {
    this.loadAllSensors()
  },
  methods: {
    loadAllSensors() {
      let self = this
      self.getRequest(
        `/sensors/_views/simple`,
        new RequestConfig()
          .onSuccess(res => {
            self.allOptions = self.mapSensorSimpleViewDtoListToSelectOptions(res.data)
            self.allOptions.forEach(sensor => {
              if (self.defaultSensorIds) {
                self.defaultSensorIds.forEach(id => {
                  if (sensor.value.id === parseInt(id)) {
                    self.selected.push(sensor)
                  }
                })
              }
            })
          })
          .onError(err => {
            console.log('Error while loading sensors:' + err)
            self.allOptions = []
          })
      )
    },
    mapSensorSimpleViewDtoListToSelectOptions(responseData) {
      let options = []
      responseData.forEach(sensor => {
        options.push({ value: sensor, text: `# ${sensor.id} - ${sensor.sensor_location.city}` })
      })
      return options
    },
    loadSensorsOfLocation(location) {
      let self = this
      self.getRequest(
        `/sensors/_views/simple?location=${location.name}`,
        new RequestConfig().onSuccess(res => {
          console.log('Sensor Response', res)
          console.log('Sensor Response data', res.data)
          self.sensorOptions = res.data
        })
      )
    },
    removeTag(tag) {
      this.selected.forEach(item => {
        if (item.value.name === tag.name && tag.id !== null) {
          this.$emit('updateTagsToDelete', tag)
        }
      })
      this.selected = this.selected.filter(item => item.text !== tag.name)
    },
    createTag(newTag) {
      let isNewTag = true
      this.selected.forEach(tag => {
        if (tag.text === newTag) {
          isNewTag = false
        }
      })
      if (isNewTag) {
        const tag = {
          text: newTag,
          value: {
            id: null,
            auto_tag: false,
            name: newTag
          }
        }
        this.selected.push(tag)
      }
    }
  }
}
</script>
