<template>
  <div class="main-container text-left">
    <b-row
      v-for="(tagFilter, index) in inputVal"
      :key="index"
      align-h="between"
      align-v="start"
      :class="index !== 0 ? 'mt-3' : ''"
    >
      <b-col cols="2" class="tag-column">
        <span>Tag-Filter #{{ index + 1 }}</span>
      </b-col>
      <b-col cols="9">
        <DatasetTagMultiselect
          id="datasetTagMultiselect"
          :allow-tag-negation="true"
          v-model="tagFilter.tags"
          is-or-filter
          :use-default-tag-ids="true"
          :default-tag-ids="tagFilter.defaultTagIds ? tagFilter.defaultTagIds : []"
        />
      </b-col>
      <b-col cols="1" class="tag-column">
        <b-button-group>
          <b-button
            v-if="inputVal.length > 1"
            variant="outline-secondary"
            size="xs"
            v-b-tooltip.hover.bottom.d400.dh50="$t('dataset.remove-tag-filter')"
            @click="removeFilter(index)"
          >
            <b-icon class="add-icon" icon="dash-lg" />
          </b-button>
          <b-button
            v-if="index + 1 === inputVal.length"
            size="xs"
            variant="outline-secondary"
            v-b-tooltip.hover.bottom.d400.dh50="$t('dataset.add-tag-filter')"
            @click="addFilter(index)"
          >
            <b-icon class="add-icon" icon="plus-lg" />
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import DatasetTagMultiselect from '@/components/DatasetTagMultiselect'

export default {
  name: 'DatasetTagMultiselectWrapper',
  components: { DatasetTagMultiselect },
  props: {
    value: {
      required: true,
      type: Array
    },
    includeAutoTags: {
      required: false,
      type: Boolean,
      default: true
    },
    allowAutoTagRemoval: {
      required: false,
      type: Boolean,
      default: true
    },
    allowTagCreation: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  mounted() {
    // this.loadAllDatasetTags()
  },
  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    addFilter(index) {
      // Clean default tag ids if action
      this.inputVal.push({
        index: index + 1,
        tags: [],
        defaultTagIds: []
      })
    },
    removeFilter(index) {
      // Clean default tag ids if action
      this.inputVal.splice(index, 1)
    }
  }
}
</script>

<style scoped lang="scss">
.tag-column {
  padding-right: 0;
  padding-top: 6px;
}
</style>
