<template>
  <GoldflamMultiselect
    v-model="selected"
    :create-tag-function="createTag"
    :empty-label="$t('components.sensorLocationFilter.list-empty-label')"
    :placeholder="
      isSensorFilterSet
        ? $t('components.sensorLocationFilter.all-locations-for-sensors')
        : $t('components.sensorLocationFilter.all-locations')
    "
    :selectable-items="locationOptions"
    :taggable="allowTagCreation"
  >
    <template v-slot:tag="props">
      <DatasetTagBadge
        v-if="props.option.value"
        :tag="{
          id: props.option.value.id,
          name: props.option.text
        }"
        :allow-removal="allowAutoTagRemoval || !props.option.value.auto_tag"
        :remove-function="removeTag"
      />
    </template>
  </GoldflamMultiselect>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import { uniqBy as _uniqBy } from 'lodash'
import GoldflamMultiselect from '@/components/GoldflamMultiselect'
import DatasetTagBadge from '@/components/DatasetTagBadge'

export default {
  name: 'LocationFilter',
  components: { GoldflamMultiselect, DatasetTagBadge },
  mixins: [ApiMixin, NotificationMixin],
  props: {
    value: {
      required: true,
      type: Array
    },
    sensorFilter: {
      required: false,
      type: Array
    },
    defaultSensorLocationIds: {
      required: false,
      type: Array
    },
    allowTagCreation: {
      required: false,
      type: Boolean,
      default: false
    },
    allowAutoTagRemoval: {
      required: false,
      type: Boolean,
      default: true
    },
    multipleSensors: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      allOptions: []
    }
  },
  watch: {
    defaultSensorLocationIds: {
      handler: function () {
        let newDefaults = []
        this.defaultSensorLocationIds.forEach(defaultId => {
          this.allOptions.forEach(sensorLocation => {
            if (sensorLocation.value.id === parseInt(defaultId)) {
              newDefaults.push(sensorLocation)
            }
          })
        })
        this.selected = newDefaults
      }
    }
  },
  computed: {
    selected: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    isSensorFilterSet() {
      return this.sensorFilter !== null && this.sensorFilter.length !== 0
    },
    locationOptions() {
      if (this.isSensorFilterSet) {
        let options = []
        this.allOptions.forEach(option => {
          this.sensorFilter.forEach(filter => {
            if (option.value.id === filter.value.sensor_location.id) {
              options.push(option)
            }
          })
        })
        return _uniqBy(options, e => {
          return e.value.id
        })
      } else {
        return this.allOptions
      }
    },
    sensorFilterName() {
      let filterIds = ''
      this.sensorFilter.forEach((filter, index) => {
        filterIds += '#' + filter.value.id
        if (index !== this.sensorFilter.length - 1) {
          filterIds += ', '
        }
      })
      return filterIds
    }
  },
  mounted() {
    this.loadAllLocations()
  },
  methods: {
    loadAllLocations() {
      let self = this
      self.getRequest(
        `/sensor_locations/_views/simple`,
        new RequestConfig()
          .onSuccess(res => {
            self.allOptions = self.mapSensorLocationSimpleDtoListToSelectOptions(res.data)
            self.allOptions.forEach(sensorLocation => {
              if (self.defaultSensorLocationIds) {
                self.defaultSensorLocationIds.forEach(id => {
                  if (sensorLocation.value.id === parseInt(id)) {
                    self.selected.push(sensorLocation)
                  }
                })
              }
            })
          })
          .onError(err => {
            console.log('Error while loading sensor locations:' + err)
            self.allOptions = []
          })
      )
    },
    mapSensorLocationSimpleDtoListToSelectOptions(responseData) {
      let options = []
      responseData.forEach(location => {
        options.push({ value: location, text: `# ${location.id} - ${location.city} - ${location.name}` })
      })
      return options
    },
    removeTag(tag) {
      this.selected.forEach(item => {
        if (item.value.name === tag.name && tag.id !== null) {
          this.$emit('updateTagsToDelete', tag)
        }
      })
      this.selected = this.selected.filter(item => item.text !== tag.name)
    },
    createTag(newTag) {
      let isNewTag = true
      this.selected.forEach(tag => {
        if (tag.text === newTag) {
          isNewTag = false
        }
      })
      if (isNewTag) {
        const tag = {
          text: newTag,
          value: {
            id: null,
            auto_tag: false,
            name: newTag
          }
        }
        this.selected.push(tag)
      }
    }
  }
}
</script>
