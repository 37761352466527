<template>
  <b-row align-h="between" align-v="start">
    <b-col cols="12" md="6" lg="3">
      <b-form-select
        v-model="localTaxonomy"
        :options="taxonomyOptions"
        @change="inputVal = []"
        class="mb-3 taxonomy-select"
      >
        <!-- This slot appears above the options from 'options' prop -->
        <template #first>
          <b-form-select-option :value="''" disabled>{{
            $t('components.taxonomyFilter.taxonomy-level')
          }}</b-form-select-option>
        </template>
      </b-form-select>
    </b-col>
    <b-col cols="12" lg="9">
      <GoldflamMultiselect
        :disable="localTaxonomy === ''"
        v-model="inputVal"
        :selectable-items="multiselectOptions"
        :placeholder="placeholderLabel"
        :taggable="allowTagCreation"
        :create-tag-function="createTag"
      >
        <template v-slot:tag="props">
          <DatasetTagBadge
            v-if="props.option.value"
            :tag="props.option.value"
            :allow-removal="allowAutoTagRemoval || !props.option.value.auto_tag"
            :remove-function="removeTag"
          />
        </template>
      </GoldflamMultiselect>
    </b-col>
  </b-row>
</template>

<script>
import GoldflamMultiselect from '@/components/GoldflamMultiselect'
import DatasetTagBadge from '@/components/DatasetTagBadge'
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import { i18n } from '@/plugins/i18n'

export default {
  name: 'TaxonomyFilter',
  components: { GoldflamMultiselect, DatasetTagBadge },
  mixins: [ApiMixin],
  props: {
    taxonomy: {
      required: true,
      type: String
    },
    value: {
      required: true,
      type: Array
    },
    allowAutoTagRemoval: {
      required: false,
      type: Boolean,
      default: true
    },
    allowTagCreation: {
      required: false,
      type: Boolean,
      default: false
    },
    defaultIds: {
      required: false,
      type: Array
    }
  },
  mounted() {
    this.getAllInsectOrders()
    this.getAllInsectFamilies()
    this.getAllInsectGenuses()
    this.getAllInsectSpecies()
  },
  data() {
    return {
      taxonomyOptions: [
        { value: 'ORDER', text: i18n.t('dataset.insect-order') },
        { value: 'FAMILY', text: i18n.t('dataset.insect-family') },
        { value: 'GENUS', text: i18n.t('dataset.insect-genus') },
        { value: 'SPECIES', text: i18n.t('dataset.insect-species') }
      ],
      allInsectOrders: [],
      allInsectFamilies: [],
      allInsectGenuses: [],
      allInsectSpecies: [],
      labels: {
        orderPlaceholder: '',
        familyPlaceholder: '',
        genusPlaceholder: '',
        speciesPlaceholder: '',
        placeholder: this.$t('components.datasetTagMultiselect.default-placeholder'),
        placeholderTaggable: this.$t('components.datasetTagMultiselect.default-placeholder-taggable'),
        tagPlaceholder: this.$t('components.datasetTagMultiselect.default-tag-placeholder'),
        listEmpty: this.$t('components.datasetTagMultiselect.list-empty-label')
      }
    }
  },
  computed: {
    localTaxonomy: {
      get() {
        return this.taxonomy
      },
      set(val) {
        this.$emit('update:taxonomy', val)
      }
    },
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    multiselectOptions() {
      let base = []
      let options = []
      if (this.localTaxonomy === 'ORDER') {
        base = this.allInsectOrders
      } else if (this.localTaxonomy === 'FAMILY') {
        base = this.allInsectFamilies
      } else if (this.localTaxonomy === 'GENUS') {
        base = this.allInsectGenuses
      } else if (this.localTaxonomy === 'SPECIES') {
        base = this.allInsectSpecies
      }
      base.forEach(item => {
        options.push({
          value: item,
          text: item.name
        })
      })
      return options
    },
    placeholderLabel() {
      const taxonomyLevel = this.localTaxonomy
      switch (taxonomyLevel) {
        case '':
          return i18n.t('components.taxonomyFilter.taxonomy-level-info')
        case 'ORDER':
          return i18n.t('components.taxonomyFilter.insect-order-placeholder')
        case 'FAMILY':
          return i18n.t('components.taxonomyFilter.insect-family-placeholder')
        case 'GENUS':
          return i18n.t('components.taxonomyFilter.insect-genus-placeholder')
        case 'SPECIES':
          return i18n.t('components.taxonomyFilter.insect-species-placeholder')
      }
      return i18n.t('components.multiselect.default-placeholder')
    }
  },
  watch: {
    defaultIds: {
      handler: function () {
        let options = []
        switch (this.taxonomy) {
          case 'ORDER':
            options = this.allInsectOrders
            break
          case 'FAMILY':
            options = this.allInsectFamilies
            break
          case 'GENUS':
            options = this.allInsectGenuses
            break
          case 'SPECIES':
            options = this.allInsectSpecies
            break
        }
        let newDefaults = []
        this.defaultIds.forEach(defaultId => {
          options.forEach(option => {
            if (parseInt(defaultId) === option.id) {
              newDefaults.push({
                value: option,
                text: option.name
              })
            }
          })
        })
        this.inputVal = newDefaults
      }
    }
  },
  methods: {
    setDefaultItems(options) {
      this.defaultIds.forEach(defaultId => {
        options.forEach(option => {
          if (parseInt(defaultId) === option.id) {
            this.inputVal.push({
              value: option,
              text: option.name
            })
          }
        })
      })
    },
    getAllInsectOrders() {
      let self = this
      self.getRequest(
        `/insect_orders`,
        new RequestConfig()
          .onSuccess(res => {
            self.allInsectOrders = res.data
            if (self.taxonomy === 'ORDER') {
              self.setDefaultItems(self.allInsectOrders)
            }
          })
          .onError(error => {
            // TODO error handling
            console.log(error)
          })
      )
    },
    getAllInsectFamilies() {
      let self = this
      self.getRequest(
        `/insect_families`,
        new RequestConfig()
          .onSuccess(res => {
            self.allInsectFamilies = res.data
            if (self.taxonomy === 'FAMILY') {
              self.setDefaultItems(self.allInsectFamilies)
            }
          })
          .onError(error => {
            // TODO error handling
            console.log(error)
          })
      )
    },
    getAllInsectGenuses() {
      let self = this
      self.getRequest(
        `/insect_genuses`,
        new RequestConfig()
          .onSuccess(res => {
            self.allInsectGenuses = res.data
            if (self.taxonomy === 'GENUS') {
              self.setDefaultItems(self.allInsectGenuses)
            }
          })
          .onError(error => {
            // TODO error handling
            console.log(error)
          })
      )
    },
    getAllInsectSpecies() {
      let self = this
      self.getRequest(
        `/insect_species`,
        new RequestConfig()
          .onSuccess(res => {
            self.allInsectSpecies = res.data
            if (self.taxonomy === 'SPECIES') {
              self.setDefaultItems(self.allInsectSpecies)
            }
          })
          .onError(error => {
            // TODO error handling
            console.log(error)
          })
      )
    },
    removeTag(tag) {
      this.inputVal.forEach(item => {
        if (item.value.name === tag.name && tag.id !== null) {
          this.$emit('updateTagsToDelete', tag)
        }
      })
      this.inputVal = this.inputVal.filter(item => item.value.name !== tag.name)
    },
    createTag(newTag) {
      let isNewTag = true
      this.inputVal.forEach(tag => {
        if (tag.text === newTag) {
          isNewTag = false
        }
      })
      if (isNewTag) {
        const tag = {
          text: newTag,
          value: {
            id: null,
            auto_tag: false,
            name: newTag
          }
        }
        this.inputVal.push(tag)
      }
    }
  }
}
</script>

<style>
.multiselect__tags-wrap {
  display: inline-flex !important;
}
.taxonomy-select {
  height: 43px;
  margin: 0px !important;
}
</style>
