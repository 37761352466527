<template>
  <b-container fluid="xxl" class="dataset-table-container">
    <b-card class="text-center">
      <h4 class="card-title mb-5">{{ $t('dataset.management') }}</h4>
      <b-row align-h="center" class="mx-0 pt-3 filter-top-row">
        <b-collapse id="collapse-1" :visible="showFilters" class="w-75">
          <b-container fluid id="headContainer" class="pl-3 pr-3 pt-4 pb-2">
            <h5 class="font-weight-light text-left">{{ $t('dataset.sensor-information') }}</h5>
            <b-row align-h="between" align-v="start">
              <b-col>
                <CombinedSensorAndSensorLocationFilter
                  :locations.sync="filterOptions.sensorLocations"
                  :sensors.sync="filterOptions.sensors"
                  :multiple-sensors="true"
                  :default-sensor-ids="defaultSensorIds"
                  :default-sensor-location-ids="defaultSensorLocationIds"
                />
              </b-col>
            </b-row>

            <h5 class="font-weight-light text-left mt-4">{{ $t('dataset.date-information') }}</h5>
            <b-row align-h="between" align-v="start">
              <b-col lg="6">
                <GoldflamDatepicker
                  id="datepickStart"
                  v-model="filterOptions.measurementStartDate"
                  :locale="this.$store.getters.getLocale"
                  :placeholder="this.$t('datetime.start-date')"
                  :max="filterOptions.measurementEndDate"
                />
              </b-col>
              <b-col lg="6">
                <GoldflamDatepicker
                  id="datepickEnd"
                  v-model="filterOptions.measurementEndDate"
                  :locale="this.$store.getters.getLocale"
                  :placeholder="this.$t('datetime.end-date')"
                  :min="filterOptions.measurementStartDate"
                />
              </b-col>
            </b-row>

            <h5 class="font-weight-light text-left mt-4">{{ $t('dataset.taxonomy') }}</h5>
            <b-row align-h="between" align-v="start">
              <b-col>
                <TaxonomyFilter
                  :taxonomy.sync="filterOptions.taxonomyFilter.taxonomy"
                  v-model="filterOptions.taxonomyFilter.ids"
                  :default-ids="defaultTaxonomyIds"
                />
              </b-col>
            </b-row>

            <h5 class="font-weight-light text-left mt-4">{{ $t('dataset.dataset-tags') }}</h5>
            <b-row align-h="between" align-v="start">
              <b-col>
                <DatasetTagMultiselectWrapper id="datasetTagMultiselect" v-model="filterOptions.tagFilters" />
              </b-col>
            </b-row>
            <b-row class="align-items-end justify-content-end mr-1 border-bottom">
              <b-button class="float-right mb-4 mt-5" size="sm" @click="clearAllFilter" variant="outline-secondary">
                {{ $t('dataset.clear-all-filters') }}
              </b-button>
              <!-- Jump to first page to prevent empty pages by reloaded data (loadAllDatasets is triggered by watcher) -->
              <!-- If already on first page, just load datasets -->
              <b-button
                :disabled="!haveFilterOptionsChanged || tableControls.tableBusy"
                @click="tableControls.currentPage !== 1 ? (tableControls.currentPage = 1) : setUrl()"
                variant="primary"
                size="sm"
                class="float-right mb-4 mt-5 ml-3"
              >
                {{ $t('dataset.apply-filters') }}</b-button
              >
            </b-row>
          </b-container>
        </b-collapse>
      </b-row>
      <b-row align-h="center" class="mx-0 filter-middle-row">
        <b-container class="w-75">
          <b-button
            @click="
              () => {
                showFilters = !showFilters
              }
            "
            variant="outline-secondary"
            size="sm"
            class="mt-3 mb-2"
            >{{ showFilters ? $t('dataset.hide-filters') : $t('dataset.show-filters') }}</b-button
          >
        </b-container>
      </b-row>
      <b-row align-h="center" class="mx-0 mb-5 filter-bottom-row">
        <span class="mb-3 float-right"
          >{{ filtersSetString === '' ? $t('dataset.no-filters-set') : $t('dataset.filters-set-for')
          }}<b>{{ filtersSetString }}</b
          >. <br />
          {{ foundDatasetsText }}
        </span>
      </b-row>

      <b-table
        id="datasetTable"
        ref="datasetTable"
        class="dataset-table text-left datatable"
        small
        striped
        bordered
        stacked="lg"
        head-variant="light"
        hover="hover"
        show-empty
        :empty-text="$t('general.table.empty-text')"
        :fields="datasetTableFields"
        :items="datasets"
        :busy="tableControls.tableBusy"
        sort-direction="desc"
        :sort-by.sync="tableControls.sortBy"
        :sort-desc.sync="tableControls.sortDesc"
        @head-clicked="writeSortPropertiesToQuery($event)"
      >
        <template #table-busy>
          <LoadingSpinner />
        </template>
        <!-- generic cell slot template to add tooltips to truncated text cells in datatables -->
        <template v-slot:cell()="row">
          <span v-ellipsis:bottom="row.value">{{ row.value }}</span>
        </template>
        <template #table-colgroup="scope">
          <col v-for="field in scope.fields" :key="field.key" :style="{ width: field.width }" />
        </template>
        <template v-slot:cell(editOperations)="row">
          <b-button
            size="xs"
            variant="outline-secondary"
            :to="{
              name: 'datasetEdit',
              params: {
                datasetId: row.item.id,
                mode: 'view',
                viewOnly: true,
                position: $route.query.page * $route.query.size + row.index
              },
              query: {
                ...$route.query,
                position: $route.query.page * $route.query.size + row.index,
                page: tableControls.currentPage - 1
              }
            }"
            class="mr-2"
          >
            <b-icon icon="eye-fill" aria-hidden="true" />
          </b-button>
          <b-button
            v-if="isAdmin || isTeamMember"
            size="xs"
            variant="outline-secondary"
            :to="{
              name: 'datasetEdit',
              params: {
                datasetId: row.item.id,
                mode: 'edit',
                viewOnly: false,
                position: $route.query.page * $route.query.size + row.index
              },
              query: {
                ...$route.query,
                position: $route.query.page * $route.query.size + row.index,
                page: tableControls.currentPage - 1
              }
            }"
            class="mr-2"
          >
            <b-icon icon="pencil" aria-hidden="true" />
          </b-button>
          <b-button
            v-if="isAdmin || isTeamMember"
            size="xs"
            variant="outline-danger"
            @click="openDeleteModal(row.item.id)"
          >
            <b-icon icon="trash" aria-hidden="true" />
          </b-button>
        </template>
        <template v-slot:cell(dataset_tags)="row">
          <div class="d-inline-flex flex-wrap">
            <DatasetTagBadge v-for="tag in row.value" :key="tag.id" :tag="tag" margin-classes="mr-2 mb-1" />
          </div>
        </template>
        <template v-slot:cell(user)="row">
          <span v-if="row.item.user.fullName">{{ row.item.user.fullName }}</span>
          <span v-else>#{{ row.item.user.id }}</span>
        </template>
      </b-table>
      <div class="overflow-auto mt-2 upload-pagination">
        <b-pagination-nav
          v-model="tableControls.currentPage"
          align="center"
          :number-of-pages="totalPages"
          :link-gen="paginationLinkGenerator"
          use-router
        />
      </div>
      <div>
        {{ $t('general.table.items-per-page') }}:
        <b-select
          id="per-page-select"
          class="w-auto"
          v-model="tableControls.perPage"
          :options="tableControls.perPageOptions"
          size="sm"
        />
      </div>
      <div
        tabindex="0"
        v-b-tooltip.top
        :title="exportDisabled ? $t('export.button-disabled') : ''"
        class="float-right mb-2 mt-2"
      >
        <b-button
          variant="primary"
          @click="openExportModal"
          :disabled="exportDisabled"
          :style="exportDisabled ? 'pointer-events: none;' : ''"
        >
          <b-icon icon="download" aria-hidden="true" class="pr-1" /> {{ $t('export.export') }}
        </b-button>
      </div>
    </b-card>

    <DeleteModal
      v-model="datasetDeleteModal.show"
      :title="$t('dataset.delete-dataset')"
      :delete-message="$t('dataset.delete-modal-warning')"
      :delete-function="deleteDatasetAndReloadDatatable"
    />

    <b-modal
      id="exportModal"
      v-model="modalControl.export.show"
      centered
      :title="$t('export.title')"
      :ok-title="$t('export.export')"
      :cancel-title="$t('general.cancel')"
      cancel-variant="outline-secondary"
      :header-bg-variant="headerBgVariant"
      :header-text-variant="headerTextVariant"
      :body-bg-variant="bodyBgVariant"
      :body-text-variant="bodyTextVariant"
      :footer-bg-variant="footerBgVariant"
      :footer-text-variant="footerTextVariant"
      @ok="exportDatasets()"
    >
      <p>{{ $t('export.message', { count: tableControls.totalRows }) }}</p>
      <p v-if="isDataCollector">{{ $t('export.message-data-collector', { count: tableControls.totalRows }) }}</p>
    </b-modal>
  </b-container>
</template>

<script>
import { ApiMixin, RequestConfig } from '@/mixins/ApiMixin'
import GoldflamDatepicker from '@/components/GoldflamDatepicker'
import { NotificationMixin } from '@/mixins/NotificationMixin'
import DeleteModal from '@/components/modals/DeleteModal'
import { DatasetDeletionMixin } from '@/mixins/DatasetDeletionMixin'
import { cloneDeep as _cloneDeep, isEmpty as _isEmpty, isEqual as _isEqual } from 'lodash'
import DatasetTagMultiselectWrapper from '@/components/DatasetTagMultiselectWrapper'
import { LocalDateTimeFormatter } from '@/util/LocalDateTimeFormatter'
import DatasetTagBadge from '@/components/DatasetTagBadge'
import LoadingSpinner from '@/components/LoadingSpinner'
import CombinedSensorAndSensorLocationFilter from '@/components/CombinedSensorAndSensorLocationFilter'
import TaxonomyFilter from '@/components/TaxonomyFilter'
import { nextTick } from 'vue'

export default {
  name: 'MainDatasets',
  components: {
    CombinedSensorAndSensorLocationFilter,
    GoldflamDatepicker,
    DatasetTagMultiselectWrapper,
    DeleteModal,
    DatasetTagBadge,
    LoadingSpinner,
    TaxonomyFilter
  },
  mixins: [ApiMixin, NotificationMixin, DatasetDeletionMixin],

  data() {
    return {
      datasets: [],
      datasetTableFields: [
        { key: 'id', label: this.$t('general.id'), sortable: true, formatter: 'formatId', width: '50px' },
        {
          key: 'measurement_datetime',
          label: this.$t('dataset.time-stamp'),
          sortable: true,
          formatter: 'formatDateTime',
          width: '145px'
        },
        {
          key: 'computed_modified',
          label: this.$t('dataset.latest-change'),
          sortable: true,
          formatter: 'formatDateTime',
          width: '145px'
        },
        { key: 'user', label: this.$t('dataset.user'), sortable: true },
        {
          key: 'sensor.id',
          label: this.$t('dataset.sensor-id'),
          sortable: true,
          formatter: 'formatId',
          width: '100px'
        },
        {
          key: 'sensor.sensor_location',
          sortable: true,
          label: this.$t('dataset.location'),
          formatter: 'formatLocation'
        },
        { key: 'dataset_tags', label: this.$t('dataset.tags'), sortable: false },
        {
          key: 'editOperations',
          label: this.$t('general.table.actions'),
          sortable: false,
          width: '80px',
          class: 'text-center'
        }
      ],
      showFilters: true,
      disableFilterWatcher: true,
      selectedDatasets: [],
      filterOptions: {
        sensors: [],
        sensorLocations: [],
        measurementStartDate: '',
        measurementEndDate: '',
        tagFilters: [
          {
            index: 0,
            tags: [],
            defaultTagIds: []
          }
        ],
        taxonomyFilter: {
          taxonomy: '',
          ids: []
        }
      },
      queryParams: {
        page: '0',
        size: '20',
        sort: 'id,desc'
      },
      defaultSensorIds: [],
      defaultSensorLocationIds: [],
      defaultTaxonomyIds: [],
      defaultTagIds: [],
      tagSuggestions: [],
      initialLoading: false,
      initialPath: '',
      previousQuery: '',
      tableControls: {
        totalRows: 0,
        currentPage: 1,
        perPage: 20,
        perPageOptions: [20, 50, 100],
        sortBy: 'id',
        sortDesc: true,
        tableBusy: false
      },
      modalControl: {
        export: {
          show: false
        }
      },
      headerBgVariant: 'dark',
      headerTextVariant: 'light',
      bodyBgVariant: 'light',
      bodyTextVariant: 'dark',
      footerBgVariant: 'light',
      footerTextVariant: 'dark',
      manuallyLoaded: false
    }
  },
  mounted() {
    // Add initial table controls to query of entering page without query params
    this.fillInitialQueryParamsIfEmpty()

    // Store initial Path
    this.initialPath = this.$route.fullPath.replace('datasets', 'datasets/_views/table')

    // Enable initial loading
    // --> loadAllDataSets() uses initial path as URL;
    // --> disables table control watchers
    this.initialLoading = true

    // Restore table controls and filter options from query params
    this.setTableControlsFromRouteQueryParams()
    this.setFilterOptionsFromRouteQueryParams()

    // Initial loading of datasets
    this.loadAllDatasets()

    // Disable initial loading with delay
    setTimeout(() => {
      this.initialLoading = false
    }, 500)
  },
  watch: {
    '$route.query': {
      handler: function () {
        // manuallyLoaded is set 'true' if loading is triggered by clicking button
        // ensures resetting of default-ids only happen on initial load or back / forward navigation
        if (!this.manuallyLoaded) {
          this.setTableControlsFromRouteQueryParams()
          this.setFilterOptionsFromRouteQueryParams()
        }
        this.loadAllDatasets()
        this.manuallyLoaded = false
      }
    },
    // -- All watchers for filter options --------------------------------------
    'filterOptions.sensors': {
      handler: function (newVal) {
        if (!_isEmpty(newVal)) {
          if (newVal.length > 0) {
            let sensorIds = this.convertIds(newVal)
            this.queryParams = Object.assign({}, this.queryParams, { sensorFilters: sensorIds })
          }
        } else {
          this.deleteFromQuery(['sensorFilters'])
        }
      }
    },
    'filterOptions.sensorLocations': {
      handler: function (newVal) {
        if (!_isEmpty(newVal)) {
          if (newVal.length > 0) {
            let sensorLocationIds = this.convertIds(newVal)
            this.queryParams = Object.assign({}, this.queryParams, { sensorLocationFilters: sensorLocationIds })
          }
        } else {
          this.deleteFromQuery(['sensorLocationFilters'])
        }
      }
    },
    'filterOptions.measurementStartDate': {
      handler: function (newVal) {
        if (!_isEmpty(newVal)) {
          this.queryParams = Object.assign({}, this.queryParams, { measurementStartDate: newVal.toString() })
        } else {
          this.deleteFromQuery(['measurementStartDate'])
        }
      }
    },
    'filterOptions.measurementEndDate': {
      handler: function (newVal) {
        if (!_isEmpty(newVal)) {
          this.queryParams = Object.assign({}, this.queryParams, { measurementEndDate: newVal.toString() })
        } else {
          this.deleteFromQuery(['measurementEndDate'])
        }
      }
    },
    'filterOptions.tagFilters': {
      handler: function (newVal) {
        let counter = 0
        // Delete all existing tagFilter entries
        let newQueryParams = _cloneDeep(this.queryParams)
        Object.entries(this.queryParams).forEach(key => {
          if (key[0].startsWith('tagFilterList')) {
            delete newQueryParams[key[0]]
          }
        })
        this.queryParams = Object.assign({}, newQueryParams)
        if (newVal) {
          // Iterate over all tagFilters and add to query params object
          newVal.forEach(tagFilter => {
            let key = `tagFilterList[${counter}]`
            if (tagFilter.tags.length !== 0) {
              this.queryParams = Object.assign({}, this.queryParams, { [key]: this.convertTags(tagFilter.tags) })
              counter += 1
            } else {
              let newQueryParams = _cloneDeep(this.queryParams)
              delete newQueryParams[key]
              this.queryParams = Object.assign({}, newQueryParams)
            }
          })
        }
      },
      deep: true
    },
    'filterOptions.taxonomyFilter': {
      handler: function (newVal) {
        if (newVal.taxonomy !== '' && newVal.ids.length > 0) {
          let taxonomyIds = this.convertIds(newVal.ids)
          this.queryParams = Object.assign({}, this.queryParams, {
            taxonomyLevel: newVal.taxonomy,
            taxonomyFilters: taxonomyIds
          })
        } else {
          this.deleteFromQuery(['taxonomyLevel', 'taxonomyFilters'])
        }
      },
      deep: true
    },
    // -- All watchers for table controls --------------------------------------
    'tableControls.perPage': function (newVal, oldVal) {
      // triggered when per-page <b-select> is changed
      if (!this.initialLoading && newVal !== oldVal && newVal !== null) {
        // avoid getting blank pages when updating 'per-page' on a high 'page' by also reducing the currentPage prop to a value which still has entries
        if (this.tableControls.totalRows > 0) {
          while (
            this.tableControls.perPage * this.tableControls.currentPage - this.tableControls.perPage >
            this.tableControls.totalRows
          ) {
            this.tableControls.currentPage--
          }
        }
        let page = this.tableControls.currentPage
        if (page > 0) {
          page = page - 1
        }
        this.queryParams = Object.assign({}, this.queryParams, { page: page, size: this.tableControls.perPage })
        this.setUrl()
      }
    }
  },
  computed: {
    filtersSetString() {
      let filterArray = []
      if (this.filterOptions.sensorLocations.length > 0) {
        filterArray.push(this.$t('dataset.sensorLocations'))
      }
      if (this.filterOptions.sensors.length > 0) {
        filterArray.push(this.$t('dataset.sensors'))
      }
      if (this.filterOptions.measurementStartDate !== '') {
        filterArray.push(this.$t('datetime.start-date'))
      }
      if (this.filterOptions.measurementEndDate !== '') {
        filterArray.push(this.$t('datetime.end-date'))
      }
      let hasTagFilter = false
      this.filterOptions.tagFilters.forEach(tagFilter => {
        if (tagFilter.tags.length > 0) {
          hasTagFilter = true
        }
      })
      if (hasTagFilter) {
        filterArray.push(this.$t('dataset.dataset-tags'))
      }
      if (this.filterOptions.taxonomyFilter.taxonomy !== '' && this.filterOptions.taxonomyFilter.ids.length > 0) {
        filterArray.push(this.$t('dataset.taxonomy'))
      }
      let filterString = ''
      filterArray.forEach((filter, index) => {
        filterString += filter
        if (index + 1 !== filterArray.length) {
          filterString += ', '
        }
      })
      return filterString
    },
    foundDatasetsText() {
      return this.tableControls.totalRows > 1 || this.tableControls.totalRows === 0
        ? this.$t('export.found-x-datasets', { count: this.tableControls.totalRows })
        : this.$t('export.found-1-dataset', { count: this.tableControls.totalRows })
    },
    totalPages() {
      if (this.tableControls.totalRows === 0) {
        return 1
      }
      return Math.ceil(this.tableControls.totalRows / this.tableControls.perPage)
    },
    haveFilterOptionsChanged() {
      return !_isEqual(this.previousQuery, this.queryParams)
    },
    exportDisabled() {
      return (
        this.filtersSetString === '' ||
        this.datasets === null ||
        this.datasets.length === 0 ||
        this.haveFilterOptionsChanged
      )
    }
  },
  methods: {
    writeSortPropertiesToQuery(event) {
      let sortBy = event
      if (sortBy === 'measurement_datetime') {
        sortBy = 'measurementDatetime'
      } else if (sortBy === 'sensor.sensor_location') {
        sortBy = 'sensor.sensorLocation.id'
      } else if (sortBy === 'computed_modified') {
        sortBy = 'computedModified'
      }
      if (sortBy !== 'dataset_tags' && sortBy !== 'editOperations') {
        this.tableControls.tableBusy = true
        // head clicked changes both sort direction and sort by
        // Time out is set so that both events are caught
        setTimeout(() => {
          let sortDirection = this.tableControls.sortDesc ? 'desc' : 'asc'
          this.queryParams = Object.assign({}, this.queryParams, { sort: `${sortBy},${sortDirection}` })
          this.setUrl()
        }, 300)
      }
    },
    fillInitialQueryParamsIfEmpty() {
      if (!this.$route.query.page) {
        this.$router.replace({
          query: { ...this.$route.query, page: this.tableControls.currentPage - 1 }
        })
      }
      if (!this.$route.query.size) {
        this.$router.replace({
          query: { ...this.$route.query, size: this.tableControls.perPage }
        })
      }
      if (!this.$route.query.sort) {
        let sortDirection = this.tableControls.sortDesc ? 'desc' : 'asc'
        this.$router.replace({
          query: { ...this.$route.query, sort: `${this.tableControls.sortBy},${sortDirection}` }
        })
      }
    },
    setTableControlsFromRouteQueryParams() {
      if (this.$route.query.page) {
        this.tableControls.currentPage = Number(this.$route.query.page) + 1
      }
      if (this.$route.query.size) {
        this.tableControls.perPage = Number(this.$route.query.size)
      }
      if (this.$route.query.sort) {
        if (this.$route.query.sort.split(',')[0] === 'measurementDatetime') {
          this.tableControls.sortBy = 'measurement_datetime'
        } else if (this.$route.query.sort.split(',')[0] === 'computedModified') {
          this.tableControls.sortBy = 'computed_modified'
        } else {
          this.tableControls.sortBy = this.$route.query.sort.split(',')[0]
        }
        this.tableControls.sortDesc = this.$route.query.sort.split(',')[1] === 'desc'
      }
    },
    paginationLinkGenerator(pageNum) {
      let sortDirection = this.tableControls.sortDesc ? 'desc' : 'asc'
      let sortBy = this.tableControls.sortBy
      if (sortBy === 'measurement_datetime') {
        sortBy = 'measurementDatetime'
      } else if (sortBy === 'computed_modified') {
        sortBy = 'computedModified'
      }
      return {
        query: {
          ...this.$route.query,
          page: pageNum - 1,
          size: this.tableControls.perPage,
          sort: `${sortBy},${sortDirection}`
        }
      }
    },
    deleteDatasetAndReloadDatatable() {
      this.deleteDataset().then(() => {
        this.loadAllDatasets()
      })
    },
    exportDatasets() {
      let url = this.$route.fullPath.replace('datasets', 'exports')
      let self = this
      this.postRequest(
        url,
        {},
        new RequestConfig()
          .onSuccess(() => {
            self.displaySuccess(self.$t('export.export-started'))
          })
          .onError(err => {
            self.displayError(err.response.data.message)
          })
      )
    },
    formatDateTime(value) {
      return LocalDateTimeFormatter.formatLocaleDateTime(value)
    },
    formatId(value) {
      return '#' + value
    },
    formatLocation(value) {
      return `#${value.id} - ${value.city} - ${value.name}`
    },
    deleteFromQuery(keys) {
      let newQueryParams = _cloneDeep(this.queryParams)
      keys.forEach(key => {
        delete newQueryParams[key]
      })
      this.queryParams = Object.assign({}, newQueryParams)
    },
    convertIds(ids) {
      let idsString = ''
      ids.forEach((item, index) => {
        idsString += item.value.id
        if (index + 1 !== ids.length) {
          idsString += ';'
        }
      })
      return idsString
    },
    convertTags(tags) {
      let filterTagsString = ''
      tags.forEach(filterTag => {
        if (filterTag.isNegated) {
          filterTagsString += '-'
        }
        filterTagsString += filterTag.value.id + ';'
      })
      return filterTagsString.substring(0, filterTagsString.length - 1)
    },
    clearAllFilter() {
      this.filterOptions.sensors = []
      this.filterOptions.sensorLocations = []
      this.filterOptions.measurementStartDate = ''
      this.filterOptions.measurementEndDate = ''
      this.filterOptions.tagFilters.forEach(tagFilter => {
        tagFilter.tags.forEach(tag => {
          tag.isNegated = false
        })
      })
      this.filterOptions.tagFilters = [
        {
          index: 0,
          tags: [],
          defaultTagIds: []
        }
      ]
      this.filterOptions.taxonomyFilter = {
        taxonomy: '',
        ids: []
      }
    },
    setFilterOptionsFromRouteQueryParams() {
      let queryFilter = this.$route.query
      let tagFilterList = []
      this.filterOptions.tagFilters = []
      nextTick(() => {
        Object.entries(queryFilter).forEach(entry => {
          if (entry[0].startsWith('tagFilterList')) {
            let tagIds = entry[1].split(';')
            let string = entry[0]
            let index = string.split('')[string.length - 2]
            tagFilterList.push({
              index: parseInt(index),
              tags: [],
              defaultTagIds: tagIds
            })
          }
        })
        if (tagFilterList.length > 0) {
          this.filterOptions.tagFilters = tagFilterList
        } else {
          this.filterOptions.tagFilters = [
            {
              index: 0,
              tags: [],
              defaultTagIds: []
            }
          ]
        }
      })
      if (queryFilter.sensorFilters && queryFilter.sensorFilters !== '') {
        this.defaultSensorIds = queryFilter.sensorFilters.split(';')
      } else {
        this.defaultSensorIds = []
      }
      if (queryFilter.sensorLocationFilters && queryFilter.sensorLocationFilters !== '') {
        this.defaultSensorLocationIds = queryFilter.sensorLocationFilters.split(';')
      } else {
        this.defaultSensorLocationIds = []
      }
      if (
        queryFilter.taxonomyLevel &&
        queryFilter.taxonomyLevel !== '' &&
        queryFilter.taxonomyFilters &&
        queryFilter.taxonomyFilters !== ''
      ) {
        this.filterOptions.taxonomyFilter.taxonomy = queryFilter.taxonomyLevel
        this.defaultTaxonomyIds = queryFilter.taxonomyFilters.split(';')
      } else {
        this.filterOptions.taxonomyFilter.taxonomy = ''
        this.defaultTaxonomyIds = []
      }
      if (queryFilter.measurementStartDate && queryFilter.measurementStartDate !== '') {
        this.filterOptions.measurementStartDate = queryFilter.measurementStartDate
      } else {
        this.filterOptions.measurementStartDate = ''
      }
      if (queryFilter.measurementEndDate && queryFilter.measurementEndDate !== '') {
        this.filterOptions.measurementEndDate = queryFilter.measurementEndDate
      } else {
        this.filterOptions.measurementEndDate = ''
      }
    },
    setUrl() {
      this.manuallyLoaded = true
      this.$router.push({
        query: this.queryParams
      })
    },
    loadAllDatasets() {
      if (this.tableControls.tableBusy) {
        console.debug(
          'Table reload was triggered but another reload is already in progress. Aborting this reload request!'
        )
        // return
      } else {
        console.debug('Table reload was triggered')
        this.tableControls.tableBusy = true
      }
      let self = this

      // use URL with query params for pagination, sorting and filtering
      let url = this.$route.fullPath.replace('datasets', 'datasets/_views/table')
      if (this.initialLoading) {
        url = this.initialPath
      }

      // issue API GET request
      self.getRequest(
        url,
        new RequestConfig().onSuccess(res => {
          if (res.data.length === 0) {
            self.tableControls.totalRows = 0
            self.datasets = res.data
          } else {
            self.tableControls.totalRows = Number(res.headers['x-total-count'])
            self.datasets = res.data
          }
          // Set timeout to ensure that filter options have been restored correctly (await watchers)
          // so that check if filter options have changed works properly
          // Also wait for setting table controls to ensure that "load filter" button stays disabled
          setTimeout(() => {
            self.previousQuery = _cloneDeep(self.queryParams)
            self.tableControls.tableBusy = false
          }, 300)
        })
      )
    },
    openExportModal() {
      this.modalControl.export.show = true
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-top-row {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  border-right: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  background-color: #f8f9fa !important;
  .border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
}
.filter-middle-row {
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  border-right: 1px solid rgba(0, 0, 0, 0.125);
  background-color: #f8f9fa !important;
}
.filter-bottom-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  border-right: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  background-color: #f8f9fa !important;
}
.filter-container {
  background-color: #f8f9fa !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.dataset-table-container {
  .dataset-pagination {
    display: flex;
    justify-content: center;
  }
}
.taxonomy-col {
  align-self: normal !important;
}
</style>
